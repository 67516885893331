import crypto from 'crypto';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import { TermFilterParams } from '../../../components/commercetools-ui/term-filter';
import { TagManager } from '../../lib/tag-manager';

type Filter = {
  terms: {
    [key: string]: {
      params: TermFilterParams[];
      selected: Record<string, boolean>;
    };
  };
  termFilteringParams: URLParam[];
  priceFilteringParams: URLParam[];
  sizeFilteringParams: URLParam[];
  sortingParam: URLParam;
  shouldSubmit: boolean;
  filterUrl?: string;
};

type FilterContextValue = {
  filter: Filter;
  debounceDelay: number;
  isUpdating: boolean;
  setFilter: Dispatch<SetStateAction<Filter>>;
  resetFilter: () => void;
  submitFilter: () => void;
  setDebounceDelay: Dispatch<SetStateAction<number>>;
  setDefaultSorting: Dispatch<SetStateAction<URLParam>>;
};

type FilterHashes = {
  termFilteringParams?: string;
  priceFilteringParams?: string;
  sizeFilteringParams?: string;
  sortingParam?: string;
  termFilters?: any;
};

const initialState: Filter = {
  terms: {},
  termFilteringParams: [],
  priceFilteringParams: [],
  sizeFilteringParams: [],
  sortingParam: undefined,
  shouldSubmit: false,
  filterUrl: undefined,
};

const FilterContext = createContext<FilterContextValue>(undefined);

const useFilter = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};

const FilterProvider = (props) => {
  const router = useRouter();
  const [filter, setFilter] = useState(initialState);
  const [isUpdating, setIsUpdating] = useState(false);
  const [defaultSorting, setDefaultSorting] = useState<URLParam>();
  const [debounceDelay, setDebounceDelay] = useState(500);
  const lastFilter = useRef<FilterHashes>(undefined);
  const { termFilteringParams, priceFilteringParams, sizeFilteringParams, sortingParam, shouldSubmit } = filter;

  const getFilterHash = (params?: URLParam | URLParam[]): string => {
    if (!params || (Array.isArray(params) && params.length === 0)) {
      return '';
    }

    if (!Array.isArray(params)) {
      const key = params.key.replace(/\[terms\]\[\d+\]/, '');
      return `${key}=${params.value}`;
    }

    return crypto
      .createHash('md5')
      .update(
        params
          .map((param) => getFilterHash(param))
          .sort()
          .join('|'),
      )
      .digest('hex');
  };

  const updateLastFilter = () => {
    lastFilter.current = {
      sortingParam: getFilterHash(filter.sortingParam ?? []),
      priceFilteringParams: getFilterHash(filter.priceFilteringParams),
      sizeFilteringParams: getFilterHash(filter.sizeFilteringParams),
      termFilteringParams: getFilterHash(filter.termFilteringParams),
      termFilters: filter.termFilteringParams,
    };
  };

  const filteringParamsChanged = (): boolean => {
    if (lastFilter.current === undefined) {
      return false;
    }

    return (
      lastFilter.current.priceFilteringParams !== getFilterHash(filter.priceFilteringParams) ||
      lastFilter.current.sizeFilteringParams !== getFilterHash(filter.sizeFilteringParams) ||
      lastFilter.current.termFilteringParams !== getFilterHash(filter.termFilteringParams)
    );
  };

  const priceFilteringParamsChanged = (): boolean => {
    if (lastFilter.current === undefined) {
      return false;
    }

    return lastFilter.current.priceFilteringParams !== getFilterHash(filter.priceFilteringParams);
  };

  const sortingParamsChanged = (): boolean => {
    return (
      lastFilter.current.sortingParam !== getFilterHash(filter.sortingParam) || filter.sortingParam === defaultSorting
    );
  };

  const resetFilter = () => {
    setFilter(initialState);
  };

  const convertRangeFilterData = (filterArray) => {
    let minPrice = 0;
    let maxPrice = 0;

    filterArray.forEach((filter) => {
      if (filter.key === 'facets[variants.price][min]') {
        minPrice = parseInt(filter.value) / 100; // Convert cents to dollars
      } else if (filter.key === 'facets[variants.price][max]') {
        maxPrice = parseInt(filter.value) / 100; // Convert cents to dollars
      }
    });

    return {
      filterCategory: 'price',
      filterValue: `${minPrice} - ${maxPrice}`,
    };
  };

  const submitFilter = (priceHasChanged = false) => {
    const params = [];
    const tagmanager = new TagManager();

    setIsUpdating(true);

    if (priceHasChanged) {
      tagmanager.customEvent('filter', convertRangeFilterData(filter.priceFilteringParams)).executePush();
    }

    if (priceFilteringParams) {
      params.push(...priceFilteringParams);
    }

    if (sortingParam) {
      params.push(sortingParam);
    }

    if (sizeFilteringParams) {
      params.push(...sizeFilteringParams);
    }

    if (termFilteringParams) {
      params.push(...termFilteringParams);
    }

    const currentUrl = updateURLParams(params, false, [], filter.filterUrl);

    router.push(currentUrl, undefined, { scroll: false }).then(() => setIsUpdating(false));
  };

  const sendTermsToDatalayer = useCallback(() => {
    if (!lastFilter.current) return;
    const addedFilters = findChangedFilterCategoryByKey(lastFilter.current.termFilters, filter.termFilteringParams);
    if (addedFilters) {
      new TagManager().customEvent('filter', addedFilters).executePush();
    }
  }, [filter.termFilteringParams]);

  const findChangedFilterCategoryByKey = (lastfilter, filter) => {
    const lastFilterMap = lastfilter.reduce((map, obj) => {
      map[obj.key] = obj.value;
      return map;
    }, {});

    for (let i = 0; i < filter.length; i++) {
      const currentFilter = filter[i];
      const lastFilterValue = lastFilterMap[currentFilter.key];

      if (!lastFilterValue) {
        const filterCategory = currentFilter.key.match(/attributes\.(.*?)\]/)[1];

        return {
          filterCategory,
          filterValue: currentFilter.value,
        };
      }
    }

    return null;
  };

  const value: FilterContextValue = useMemo(
    () => ({
      filter,
      debounceDelay,
      isUpdating,
      setFilter,
      resetFilter,
      submitFilter,
      setDebounceDelay,
      setDefaultSorting,
    }),
    [filter, debounceDelay, isUpdating],
  );

  const debouncedSubmit = useDebouncedCallback((priceHasChanged) => {
    submitFilter(priceHasChanged);
  }, debounceDelay);

  useEffect(() => {
    if (filter.shouldSubmit && filteringParamsChanged()) {
      sendTermsToDatalayer();
      if (debounceDelay > 0) {
        debouncedSubmit(priceFilteringParamsChanged());
      } else {
        submitFilter(priceFilteringParamsChanged());
      }
    }
    updateLastFilter();
  }, [termFilteringParams, sizeFilteringParams, priceFilteringParams]);

  useEffect(() => {
    if (shouldSubmit && sortingParamsChanged()) {
      updateLastFilter();
      submitFilter();
    }
  }, [sortingParam]);

  useEffect(() => {
    if (defaultSorting) {
      setFilter((filter) => ({
        ...filter,
        shouldSubmit: true,
        sortingParam: defaultSorting,
      }));
    }
  }, [defaultSorting]);

  return <FilterContext.Provider value={value} {...props} />;
};

export { FilterProvider, useFilter, initialState as initialFilterState };
