import { LineItem } from '@Types/cart/LineItem';
import { CartError } from './CartError';

export class CartItemError extends CartError {
  public readonly items?: LineItem[];
  public readonly skus?: string[];

  constructor(message: string, statusCode: string | number, items: LineItem[] | string[]) {
    super(message, statusCode);
    this.name = 'cart_item_error';

    this.items = [];
    this.skus = [];

    items.forEach((item: LineItem | string | number) => {
      if (typeof item === 'string' || typeof item === 'number') {
        this.skus.push(item + '');
      } else {
        this.items.push(item);
      }
    });
  }
}
