import * as React from 'react';
import { SWRConfig } from 'swr';
import CheckoutProvider from '@Provider/revelo-checkout';
import { fetchApiHub } from '../../lib/fetch-api-hub';
import DarkModeProvider from '../dark-mode';
import { FilterProvider } from '../filter';
import { FrontasticState, getFrontasticState } from './FrontasticState';

const initialState: FrontasticState = {
  useCart: {} as any,
  useAccount: {} as any,
  useWishlist: {} as any,
  useAdyen: {} as any,
  useNavigation: {} as any,
  useTrustedShops: {} as any,
  useProject: {} as any,
  useNewsletter: {} as any,
  useBrands: {} as any,
  useSearch: {} as any,
};

export const FrontasticContext = React.createContext<FrontasticState>(initialState);

type Props = {
  children: React.ReactNode;
};

export const FrontasticProvider: React.FC<Props> = ({ children }) => {
  const state: FrontasticState = getFrontasticState();
  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <DarkModeProvider>
        <FilterProvider>
          <CheckoutProvider>
            <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
          </CheckoutProvider>
        </FilterProvider>
      </DarkModeProvider>
    </SWRConfig>
  );
};

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};

export const useCart = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useCart;
};

export const useAccount = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useAccount;
};

export const useWishlist = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useWishlist;
};

export const useAdyen = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useAdyen;
};

export const useNavigation = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useNavigation;
};

export const useTrustedShops = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useTrustedShops;
};

export const useProject = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useProject;
};

export const useNewsletter = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useNewsletter;
};

export const useBrands = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useBrands;
};

export const useSearch = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useSearch;
};
