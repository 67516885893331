import CookiebotConfig from './interfaces/CookiebotConfig';
import ScriptNodeSnippets from './interfaces/ScriptNodeSnippets';
import { Snippets } from './Snippets';
import ScriptUtils from '../../../helpers/utils/scriptUtils';

// todo - use a more dynamic approach to the insert index, right now we rely on the tag manager to be placed "on top"
// (position 0 and 1) of the head nodes, otherwise index 3 will not put the Cookiebot script after the tag manager!
export class CookiebotManager {
  public static initialize(config: CookiebotConfig): void {
    ScriptUtils.insertScriptAt(
      CookiebotManager.getScriptNode('cookie-consent', Snippets.gtag(config.dataLayerName ?? 'dataLayer')),
      0,
    );
    ScriptUtils.insertScriptAt(
      CookiebotManager.getScriptNode('Cookiebot', Snippets.cmp(config.cbid ?? `abcdef12-3456-7890-abcd-ef1234567890`)),
      3,
    );
  }

  private static getScriptNode(id: string, snippets: ScriptNodeSnippets): Node {
    return ScriptUtils.getScriptNode(id, snippets.parameters, snippets.script);
  }
}
