import { Log } from '../../../helpers/errorLogger';
import { ResponseError } from '../fetch-api-hub';
import { RedirectResponse } from '../types';

export class ResponseHandler {
  public static async handleApiHubResponseError(
    error: any,
    logEnabled = true,
  ): Promise<RedirectResponse | object | string> {
    if (error instanceof ResponseError) {
      const errorDetails = await ResponseHandler.getResponseErrorDetails(error);
      if (logEnabled) {
        Log.error(errorDetails);
      }
      return errorDetails;
    }

    if (logEnabled) {
      Log.error(`Network error:  ${error}`);
    }

    return `Network error:  ${error}`;
  }

  public static isRedirectResponse(response: any): response is RedirectResponse {
    return (response as RedirectResponse).target !== undefined;
  }

  private static async getResponseErrorDetails(error: ResponseError): Promise<object | string> {
    try {
      const response = error.getResponse();
      const contentType = response.headers.get('content-type')?.toLowerCase();
      if (contentType && !contentType.includes('json')) {
        return await response.text();
      }
      return await response.json();
    } catch (error: any) {
      // Invalid json format, so we simply return the response body as text.
      console.error(`Failed to get response error details`, error);
    }

    return { error: error.message, errorCode: 'unknown_response_error' };
  }
}
