export default class ScriptUtils {
  public static getScriptNode(id?: string, parameters?: Record<string, any>, content?: string): Node {
    const node = document.createElement('script');

    if (id?.trim().length > 0) {
      node.id = id.trim();
    }

    if (content?.trim().length > 0) {
      node.innerHTML = content;
    }

    for (const key in parameters) {
      switch (true) {
        case parameters[key] === true:
          node.setAttribute(key, '');
          break;
        case typeof parameters[key] === 'string':
        case typeof parameters[key] === 'number':
          node.setAttribute(key, parameters[key]?.toString());
          break;
        default:
        // for now, simply ignore unsupported values ...
      }
    }

    return node;
  }

  public static insertScriptAt(script: Node, index: number): void {
    if (index < 0) {
      throw new Error(`Invalid index ${index} for script insertion`);
    }

    if (document.head.hasChildNodes() && index < document.head.childNodes.length) {
      document.head.insertBefore(script, document.head.childNodes[index]);
      return;
    }

    document.head.appendChild(script);
  }
}
