import { Address } from '@Types/account/Address';
import { Order } from '@Types/cart/Order';
import { CheckoutData } from '@Types/checkout';
import * as yup from 'yup';

// nekom definition of a valid email address, they will not import orders with invalid email
const nekomEmailExpression = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,64}$/;
const phoneNumberExpression = /^(\+|0{1,2})[1-9]+([-\/\s]?[0-9]+)+$/;
const postalCodeExpressions = {
  at: /^[0-9]{4}$/g,
  de: /^[0-9]{5}$/g,
  _default: /^[A-Za-z0-9\- ]{1,8}$/g,
};
const urlExpression =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
const numberExpression = /.*[0-9].*/;

export const isCheckoutDataValid = (checkoutData: CheckoutData) => {
  const schemeEmail = yup.string().email();
  const schemeReq = yup.string().required();

  return (
    schemeReq.isValidSync(checkoutData.email) &&
    schemeReq.isValidSync(checkoutData.selectedPaymentMethod) &&
    schemeReq.isValidSync(checkoutData.shippingAddress?.phone) &&
    schemeEmail.isValidSync(checkoutData.email) &&
    (checkoutData.billingSameAsShipping || isAddressValid(checkoutData.shippingAddress)) &&
    isAddressValid(checkoutData.billingAddress)
  );
};

export const isAddressValid = (address: Address) => {
  const addressShape = {
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    streetName: yup.string().trim().required(),
    streetNumber: yup.string().trim().required(),
    postalCode: yup.string().trim().required(),
    city: yup.string().trim().required(),
    country: yup.string().trim().required(),
  };

  const scheme = yup.object().shape(addressShape);

  return scheme.isValidSync(address);
};

export const isMollieUrl = (url: string) => {
  return yup.string().matches(urlExpression).isValidSync(url);
};

export const isItemsListValid = (cartData: Order) => {
  //some products are out of stock?
  return !cartData?.lineItems?.find((item) => !item.variant.isOnStock);
};

export const isValidPostalCode = (postalCode: string | number, country: string, throwError?: boolean): boolean => {
  const pattern = postalCodeExpressions[country.toLowerCase()] || undefined;
  if (pattern) {
    return yup.string().matches(pattern).isValidSync(postalCode.toString());
  }

  if (throwError) {
    throw new Error(`Unsupported country ${country}`);
  }

  return yup.string().matches(postalCodeExpressions['_default']).isValidSync(postalCode.toString());
};

export const isValidPhoneNumber = (phoneNumber: string | number): boolean => {
  return yup.string().matches(phoneNumberExpression).isValidSync(phoneNumber.toString());
};

export const isValidEmail = (emailAddress: string): boolean => {
  return (
    yup.string().email().isValidSync(emailAddress) &&
    yup.string().matches(nekomEmailExpression).isValidSync(emailAddress)
  );
};

export const isValidRegistration = (data: any): boolean => {
  return (
    yup.string().required().isValidSync(data.email) &&
    yup.string().required().isValidSync(data.password) &&
    yup.string().required().isValidSync(data.confirmPassword) &&
    yup.string().oneOf([data.password]).isValidSync(data.confirmPassword) &&
    isValidEmail(data.email)
  );
};

export const isValidHouseNumber = (houseNumber: string): boolean => {
  return (
    yup.string().required().isValidSync(houseNumber) && yup.string().matches(numberExpression).isValidSync(houseNumber)
  );
};

export const isValidStringField = (fieldValue: string): boolean => {
  return yup.string().trim().required().isValidSync(fieldValue);
};
